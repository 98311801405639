import { Link } from "gatsby";
import React from "react";

export function EventsTeaser({
  heading,
  children,
  to,
  src,
  buttonLabel = "View events",
}) {
  return (
    <div className="event-teaser">
      <img src={src} alt={heading} />
      <h3>{heading}</h3>
      <div className="event-teaser__content">{children}</div>
      <Link to={to}>
        <button className="btn">{buttonLabel}</button>
      </Link>
    </div>
  );
}
