import { Card } from "components/Card";
import { Content } from "components/Content";
import { EventsTeaser } from "components/EventsTeaser";
import { Hero } from "components/Hero";
import { List } from "components/List";
import { SocialSlider } from "components/SocialSlider";
import { StaticImage } from "gatsby-plugin-image";
import { useIsCompact } from "hooks/useIsCompact";
import React, { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";

const staticImgProps = {
  alt: "",
  layout: "fullWidth",
  objectFit: "cover",
  objectPosition: "center",
};

const carouselImages = {
  1: {
    node: (
      <StaticImage src="../images/carousel/hero1.jpg" {...staticImgProps} />
    ),
    src: "/carousel/hero1.jpg",
    srcMobClassName: "heroMob1",
  },
  2: {
    node: (
      <StaticImage src="../images/carousel/hero2new.jpg" {...staticImgProps} />
    ),
    src: "/carousel/hero2new.jpg",
  },
  3: {
    node: <StaticImage src="/articles-hero.jpg" {...staticImgProps} />,
    src: "/articles-hero.jpg",
    srcMobClassName: "heroMob3",
  },
  4: {
    node: <StaticImage src="/warehouse-hero.jpg" {...staticImgProps} />,
    src: "/warehouse-hero.jpg",
    srcMobClassName: "heroMob4",
  },
};

function IndexPage(p) {
  const title = "Manchester Students Group";
  const description = `Welcome to Manchester Students' Group. Unlock your uni experience and get real information on your city, through advice, recommendations, events and more... For students. By students.`;

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:url`,
            content: p.location.href,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Hero
        carouselImages={[
          carouselImages["1"],
          carouselImages["2"],
          carouselImages["3"],
          carouselImages["4"],
        ]}
        carouselContent={[
          {
            header: (
              <span>
                Welcome to <ins>Manchester Students Group</ins>
              </span>
            ),
            body: (
              <>
                <p>
                  Unlock your uni experience and get real information on your
                  city, through advice, recommendations, events and more...
                </p>
                <p>For students. By students.</p>
              </>
            ),
          },
          {
            header: <span>Events</span>,
            body: (
              <>
                <p>
                  Our hand pick of the best events around Manchester. Find
                  exclusive sold out tickets or discounts to the biggest shows
                  around.
                </p>
              </>
            ),
            button: {
              to: "/events/featured",
              children: "View events",
            },
          },
          {
            header: <span>Articles</span>,
            body: (
              <>
                <p>
                  Stay in the know with guides on how to survive uni, news
                  updates around campus and things to do around the city -
                  written by students
                </p>
              </>
            ),
            button: {
              to: "/articles",
              children: "View articles",
            },
          },
          {
            header: <span>Warehouse project</span>,
            body: (
              <>
                <p>Exclusive prices for the biggest night in the UK!</p>
              </>
            ),
            button: {
              to: "/events/warehouse-project",
              children: "View events",
            },
          },
        ]}
        image={carouselImages["3"]}
      ></Hero>
      <div style={{ overflow: "hidden" }}>
        <Content type="home">
          <List>
            <Card heading="">
              <div className="home__events-grid">
                <EventsTeaser
                  heading="Articles"
                  src="/articles-hero.jpg"
                  to="/articles"
                  buttonLabel="View articles"
                >
                  Stay in the know with guides on how to survive uni, news
                  updates around campus and things to do around the city -
                  written by students
                </EventsTeaser>
                <EventsTeaser
                  heading="Featured events"
                  src="/events2new.jpg"
                  to="/events/featured"
                >
                  Our handpicked selection of the best nights out Manchester has
                  to offer
                </EventsTeaser>
                <EventsTeaser
                  heading="The Warehouse Project"
                  src="/warehouse-hero.jpg"
                  to="/events/warehouse-project"
                >
                  Exclusive prices for the biggest night in the UK
                </EventsTeaser>
              </div>
            </Card>
            <SocialSlider />
          </List>
        </Content>
      </div>
    </React.Fragment>
  );
}

export default IndexPage;
