import React from "react";

export function Card({ heading, children }) {
  return (
    <div className="card">
      <div className="card__content">
        <h2>{heading}</h2>
        <div>{children}</div>
      </div>
    </div>
  );
}
