import React, { useState } from "react";
import IconChevronLeft from "../svgs/IconChevronLeft.svg";
import IconChevronRight from "../svgs/IconChevronRight.svg";
import IconInfluencer from "../svgs/IconInfluencer.svg";

const items = [
  // ({ ...props }) => (
  //   <div className="social_slider__text" {...props}>
  //     <p>
  //       Sign up to our mailer to be the first to hear about discounts, event
  //       announcements and important info.
  //     </p>
  //     <p>
  //       <a
  //         href="/mailing-list"
  //       >
  //         Join our Mailing list
  //       </a>
  //     </p>
  //   </div>
  // ),
  ({ ...props }) => (
    <div className="social_slider__text" {...props}>
      <p>
        Looking for jobs, a house/flatmate or a ticket to a sold out event? Need
        to get a message out to everyone quickly, or just looking for advice?
      </p>
      <p>
        <a
          href="https://www.facebook.com/groups/ManchesterStudentsGroup"
          target="_blank"
          rel="noopener noreferrer"
        >
          Follow us on Facebook
        </a>
      </p>
    </div>
  ),
  ({ ...props }) => (
    <div className="social_slider__text" {...props}>
      <p>Want to keep up to date with news, giveaways, and memes?</p>
      <p>
        <a
          href="https://www.instagram.com/manchesterstudentsgroup/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Follow us on Instagram
        </a>
      </p>
    </div>
  ),
  ({ ...props }) => (
    <div className="social_slider__text" {...props}>
      <p>The best videos from our community.</p>
      <p>
        <a
          href="https://www.tiktok.com/@manchesterstudentsgroup"
          target="_blank"
          rel="noopener noreferrer"
        >
          Follow us on TikTok
        </a>
      </p>
    </div>
  ),
];

export function SocialSlider() {
  const [index, setIndex] = useState(0);

  return (
    <div className="social_slider">
      <div className="social_slider__container">
        <div className="social_slider__grid">
          {items.map((Text, i) => (
            <Text
              key={i}
              style={{ transform: `translate(-${index * 100}%)` }}
              aria-hidden={index !== i}
              inert={index === i ? null : ""}
            />
          ))}
        </div>
        <div className="social_slider__buttons">
          <button onClick={() => setIndex(index - 1)} disabled={index <= 0}>
            <IconChevronLeft />
          </button>
          <button
            onClick={() => setIndex(index + 1)}
            disabled={index >= items.length - 1}
          >
            <IconChevronRight />
          </button>
        </div>
      </div>
      <div className="social_slider__image">
        <IconInfluencer />
      </div>
    </div>
  );
}
